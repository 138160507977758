import { deleteRequest, getRequest, postRequest, putRequest } from '..';

export function add(data) {
  return postRequest('/event', data);
}

export function edit(data) {
  return putRequest('/event', data)
}

export function get(id) {
  return getRequest(`/event/${id}`);
}

export function getAll() {
  return postRequest('/event/everything');
}

export function remove(id) {
  return deleteRequest(`/event/${id}`);
}

export function addUsersToRestrictedEvent(invitedUsers, eventId) {
  return postRequest(`/event/restricted/${eventId}`, { invitedUsers });
}

export function removeUserFromRestrictedEvent(userId, eventId) {
  return deleteRequest(`/event/restricted/${eventId}/${userId}`);
}

export function addGroupsToRestrictedEvent(groupIds, eventId) {
  return postRequest(`/event/restricted/${eventId}/groups`, groupIds);
}

export function removeGroupFromRestrictedEvent(groupId, eventId) {
  return deleteRequest(`/event/restricted/${eventId}/groups/${groupId}`);
}