<template>
  <div
    class="row mb-2"
  >
    <div 
      class="col-4"
    >
      <dropdown
        label="Groep toevoegen"
        :options="groupOptions" 
        @update:value="addGroup"
      />
    </div>
  </div>

  <loading-spinner 
    v-if="loading"
    text-align="center"
  />
  
  <div 
    v-if="!loading"
    class="row"
  >
    <div
      v-for="group in filteredGroups"
      :key="group.id"
      class="col-6 col-sm-4 my-3"
    >
      <group-card
        :group="group"
        @deleted="markGroupAsDeleted"
      />
    </div>
  </div>
</template>

<script>
import Dropdown from '@/components/UI/Dropdown.vue';
import { getAllGroups } from '@/api/providers/groups';

import GroupCard from './GroupCard.vue';

import LoadingSpinner from '@/components/UI/LoadingSpinner.vue';

export default {
  components: {
    Dropdown,
    LoadingSpinner,
    GroupCard
  },

  expose: [ 'addedGroupIds', 'removedGroupIds'],

  props: {
    groupIds: { type: Array, required: true },
    showUpdate: { type: Boolean, default: true },
  },

  data() {
    return {
      groupOptions: [],
      selectedGroups: [],
      groups: [],
      loading: true
    };
  },

  computed: {
    filteredGroups() { return this.selectedGroups.filter(g => !g.isDeleted); },
    addedGroupIds() {
      return this.selectedGroups.filter(g => g.isNew && !g.isDeleted).map(g => g.id);
    },
    removedGroupIds() {
      return this.selectedGroups.filter(g => !g.isNew && g.isDeleted).map(g => g.id);
    }
  },

  watch: {
    groupIds: function(newValue) {
      this.ensureInitialization(newValue);
    },

    groups: function(newValue) {
      this.ensureInitialization(newValue);
    }
  },

  async mounted() {
    try {
      this.groups = await getAllGroups() ?? [];
      this.initializeDropdownOptions();
      this.loading = false;
    } catch {
      return;
    }
  },

  methods: {
    initializeDropdownOptions() {
      this.groupOptions = this.groups.map(g => {
        return {
          value: g.id,
          label: g.title
        };
      });
    },

    initializeSelectedGroups() {
      const selectedGroups = this.groups.filter(g => 
        (this.groupIds ?? []).includes(g.id)
      );

      for (const group of selectedGroups) {
        this.selectedGroups.push(group);
      }
    },

    addGroup(id) {
      if (this.selectedGroups.find(u => u.id === id) != null) return;

      let option = this.groupOptions.find(o => o.value === id);
      if (option == null) return;

      this.selectedGroups.push({
        id: option.value,
        title: option.label,
        isNew: true
      });
    },

    markGroupAsDeleted(id) {
      const groupIndex = this.selectedGroups.findIndex(u => u.id === id);
      if (groupIndex < 0) return;

      this.selectedGroups[groupIndex] = { 
        ...this.selectedGroups[groupIndex], 
        isDeleted: true
      };
    },

    ensureInitialization(groupList) {
      if (groupList != []) {
        this.initializeSelectedGroups();
      }
    }
  },
}
</script>