<template>
  <div id="summary" class="card card-body">
    <div class="row align-items-center">
      <div class="summary__image-column">
        <article-image-upload
          :url="visualImage"
          ref="imageUploadRef"
          @updated="onImageUpdated"
        />
      </div>
      
      <div class="col">
        <h5 class="mb-1 font-weight-bolder">
          {{ title }}
        </h5>

        <p class="mb-0 font-weight-normal text-sm">
          {{ venueName }}

          <template v-if="venueName && parsedDateTimeRange">
            //
          </template>

          {{ parsedDateTimeRange }}
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import ArticleImageUpload from '@/components/Shared/ArticleImageUpload/ArticleImageUpload.vue';
import { parseDateTimeRange } from '@/helpers/parsers';

export default {
  components: {
    ArticleImageUpload
  },

  props: {
    endDateTime: {
      type: String,
      required: true,
    },
    showUpdate: {
      type: Boolean,
      default: true,
    },
    startDateTime: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    venueName: {
      type: String,
      required: true,
    },
    visualImage: {
      type: String,
      required: true,
    },
    visualImageData: {
      type: String,
      default: '',
    },
  },

  emits: [
    'update:visualImage',
    'update:visualImageData',
  ],

  computed: {
    parsedDateTimeRange() {
      return this.parseDateTimeRange(this.startDateTime, this.endDateTime);
    }
  },

  methods: {
    parseDateTimeRange,

    visualImageChanged({ target }) {
      const reader = new FileReader();
      reader.onload = ({ target }) => {
        this.$emit('update:visualImageData', target.result);
      };
      reader.readAsDataURL(target.files[0]);
    },

    removevisualImage() {
      if (!confirm('Weet je zeker dat je de afbeelding wil verwijderen?')) {
        return;
      }

      this.$emit('update:visualImage', '');
      this.$emit('update:visualImageData', '');
    },

    onImageUpdated(data) {
      this.$emit('update:visualImageData', data);
    }
  },
};
</script>

<style scoped lang="scss">
.summary {

  &__image {
  
    &-column {
      width: 120px;
    }
  }
}

.summary-visual {

  &__image {
    aspect-ratio: 1;
    object-fit: cover;
  }

  &-remove {
    transition: opacity .2s;

    &:not(:hover) {
      opacity: 0;
    }

    &__background {
      opacity: .75;
    }
  }
}
</style>
