<template>
  <div class="row mt-4">
    <div class="col-6">
      <base-select
        v-model="locationIdHandler"
        label="Locatie"
        :options="locationOptions"
      />
    </div>

    <div class="col-6">
      <div class="row">
        <div class="col-12">
          <material-input
            id="venue-name"
            v-model="venueNameHandler"
            :disabled="!!locationId"
            is-required
            label="Locatie naam"
            placeholder="Vul een locatie naam in"
            variant="static"
          />
        </div>
      </div>

      <div class="row mt-4">
        <div class="col-12">
          <material-input
            id="address"
            v-model="addressHandler"
            :disabled="!!locationId"
            is-required
            label="Adres"
            placeholder="Vul een adres in"
            variant="static"
          />
        </div>
      </div>

      <div class="row mt-4">
        <div class="col-12">
          <material-input
            id="zip-code"
            v-model="zipCodeHandler"
            :disabled="!!locationId"
            is-required
            label="Postcode"
            placeholder="Vul een postcode in"
            variant="static"
          />
        </div>
      </div>

      <div class="row mt-4">
        <div class="col-12">
          <material-input
            id="city"
            v-model="cityHandler"
            :disabled="!!locationId"
            is-required
            label="Stad"
            placeholder="Vul een stad in"
            variant="static"
          />
        </div>
      </div>
    </div>
  </div>

  <material-button
    v-if="showUpdate"
    class="float-end mt-6 mb-0"
    color="dark"
    variant="gradient"
    size="sm"
  >
    Update gegevens
  </material-button>
</template>

<script>
import { getAll as getAllLocations } from '@/api/providers/locations';
import { createVModelHandlers } from '@/helpers/vue';
import BaseSelect from '@/components/Forms/BaseSelect.vue';
import MaterialButton from '@/components/MaterialButton.vue';
import MaterialInput from '@/components/MaterialInput.vue';

export default {
  name: 'LocationInfo',

  components: {
    BaseSelect,
    MaterialButton,
    MaterialInput,
  },

  props: {
    address: {
      type: String,
      required: true,
    },
    city: {
      type: String,
      required: true,
    },
    locationId: {
      type: [
        Number,
        String,
      ],
      default: '',
    },
    showUpdate: {
      type: Boolean,
      default: true,
    },
    venueName: {
      type: String,
      required: true,
    },
    zipCode: {
      type: String,
      required: true,
    },
  },

  emits: [
    'update:address',
    'update:city',
    'update:locationId',
    'update:venueName',
    'update:zipCode',
  ],

  data: () => ({
    locations: [],
  }),

  computed: {
    ...createVModelHandlers(
      'address',
      'city',
      'locationId',
      'venueName',
      'zipCode',
    ),

    currentLocation() {
      return this.locations.find(({ id }) => id === this.locationIdHandler);
    },

    locationOptions() {
      return this.locations.map(({ id, title }) => ({
        code: id,
        label: title,
      }));
    },
  },

  watch: {
    locationIdHandler() {
      this.updateCurrentLocationData();
    },
  },

  mounted() {
    this.loadLocations();
  },

  methods: {
    loadLocations() {
      getAllLocations()
        .then((locations) => this.locations = locations || [])
        .catch((error) => error.default());
    },

    updateCurrentLocationData() {
      if (!this.currentLocation) {
        return;
      }

      const {
        address,
        city,
        title,
        zipcode,
      } = this.currentLocation;

      this.addressHandler = address;
      this.cityHandler = city;
      this.venueNameHandler = title;
      this.zipCodeHandler = zipcode;
    },
  },
};
</script>
