<template>
  <div class="card user-card">
    <div class="p-3 card-body">
      <div class="d-flex mt-n2">
        <div class="mt-n4 overflow-hidden flex-shrink-0">
          <profile-picture 
            :width-and-height-in-pixels="50" 
            :background-image-url="group.image" />
        </div>
        <div class="my-auto ms-3">
          <h6 class="mb-0">
            {{ group.title }}
          </h6>
        </div>
        <div class="ms-auto">
        <dropdown-menu
          button-padding-class="ps-4"
          :items="createDropdownOptions(group)"
        />
        </div>
      </div>
      <div
        v-if="showPermissions"
      >
        <div
          class="d-inline-block w-50"
        >
          <material-switch
            :name="`group-${group.id}-create`"
          >
            Bericht aanmaken
          </material-switch>
        </div>
        <div
          class="d-inline-block w-50"
        >
          <material-switch
            :name="`group-${group.id}-react`"
          >
            Reageren
          </material-switch>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import MaterialSwitch from '@/components/MaterialSwitch.vue';
import ProfilePicture from '@/components/UI/ProfilePicture.vue';
import DropdownMenu from '@/components/UI/DropdownMenu.vue';
import { createYesNoSweetAlert } from '@/helpers/sweetalert';

export default {
  components: {
    ProfilePicture,
    DropdownMenu,
    MaterialSwitch
  },

  props: {
    group: { type: Object, required: true },
    showPermissions: { type: Boolean, default: false }
  },

  emits: [
    'deleted'
  ],

  methods: {
    createDropdownOptions(group) {
      return [
        {
          handler: e => {
            e.preventDefault();
            this.removeGroupFromGroup(group)
          },
          name: `${group.title} verwijderen`
        },
      ];
    },

    removeGroupFromGroup(group) {
      createYesNoSweetAlert(
        `Weet u zeker dat u ${group.title} wilt ontkoppelen?`,
        () => this.$emit('deleted', group.id)
      );
    },
  },
}

</script>