<template>
  <div
    :class="classes"
    role="status"
  >
    <span class="sr-only">Loading...</span>
  </div>
</template>

<script>
export default {
  props: {
    grow: { type: Boolean },
    color: { type: String, default: undefined },
    small: { type: Boolean }
  },

  computed: {
    classes() {
      const classes = [];
      classes.push(this.grow ? 'spinner-grow' : 'spinner-border');
      if (this.color) classes.push(`text-${this.color}`);
      if (this.small) classes.push(this.grow ? 'spinner-grow-sm' : 'spinner-border-sm');

      return classes;
    }
  }
}
</script>