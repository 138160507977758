import { deleteRequest, getRequest, postRequest, putRequest } from '..';

export function addGroup(title, userIds) {
  return postRequest('/group', { 
    title: title, 
    userIds: userIds 
  });
}

export function editGroup(
  groupId,
  newTitle,
  addedUserIds,
  removedUserIds
) {
  return putRequest('/group', {
    groupId,
    newTitle: newTitle,
    addedUserIds,
    removedUserIds
  })
}

export function getGroup(id) {
  return getRequest(`/group/${id}`);
}

export function getAllGroups() {
  return getRequest('/group');
}

export function removeGroup(id) {
  return deleteRequest(`/group/${id}`);
}